import React from 'react';

function Subtitle() {
  return (
    <div>
      <h3 className='Description'>It is so nice to meet you!</h3>
    </div>
  )
}

export default Subtitle;