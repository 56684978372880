import React from 'react';
function Title() {

  return (
    <div>
      <div className='Title-Container'>
        <h2 className='Title'>Hi, I'm Michela.</h2>
      </div>
    </div>
  )
}

export default Title;